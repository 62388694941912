.new-goker-button-area {
  position: absolute;
  right: 15px;
  top: 10px;
}

.pagination-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}